<template>
  <v-card>
    <v-card-title class="text-h6">Tạo lệnh kiểm kê</v-card-title>

    <v-card-text>
      <v-row dense>
        <v-col cols="4">
          <select-warehouse-model
            v-model="item.id_warehouse"
            @change="getCustomer"
          ></select-warehouse-model>
        </v-col>
        <v-col cols="4">
          <date-picker-model
            v-model="item.start_date"
            custom-class="c-input-small"
            label="Bắt đầu"
            :single-line="false"
          ></date-picker-model>
        </v-col>
        <v-col cols="4">
          <date-picker-model
            v-model="item.end_date"
            custom-class="c-input-small"
            label="Kết thúc"
            :single-line="false"
          ></date-picker-model>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="item.name"
            class="c-input-small"
            label="Nội dung"
            dense
            outlined
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="item.classify"
            :items="classifyOptions"
            label="Kiểm kê"
            outlined
            dense
            hide-details
            class="c-input-small"
            @change="onClassifyChanged"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="item.id_customer"
            :items="customerOptions"
            :disabled="item.classify != 2"
            label="Khách hàng"
            outlined
            dense
            hide-details
            class="c-input-small"
          ></v-autocomplete>
        </v-col>
        <v-col cols="4">
          <v-autocomplete
            v-model="item.type"
            :items="typeOptions"
            label="Phương pháp"
            outlined
            dense
            hide-details
            class="c-input-small"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="item.skus"
            :disabled="item.type != 2"
            label="Danh sách"
            placeholder="Sku1;Sku2;Sku3;..."
            outlined
            dense
            hide-details
            class="c-input-small"
            rows="3"
            @paste.prevent.stop="onSkuPaste"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" class="text-center"></th>
                  <th role="columnheader" class="text-center">Hiện tại</th>
                  <th role="columnheader" class="text-center">
                    Dự báo cần kiểm kê
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>SKU:</td>
                  <td>{{ formatNumber(count.total_sku) }}</td>
                  <td>{{ formatNumber(count.forecast_sku) }}</td>
                </tr>
                <tr class="text-center">
                  <td>Số lượng:</td>
                  <td>{{ formatNumber(count.total_item) }}</td>
                  <td>{{ formatNumber(count.forecast_item) }}</td>
                </tr>
                <tr class="text-center">
                  <td>Vị trí:</td>
                  <td>{{ formatNumber(count.total_location) }}</td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-col cols="12" class="error--text">
          * Dự báo số lượng cần kiểm kê không tính đến hàng hoá có thể nhập
          trong thời gian từ nay đến khi kiểm kê.
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import {
  INVENTORY_DEFAULT,
  INVENTORY_STATUS_OPTIONS,
  INVENTORY_TYPE_OPTIONS,
  INVENTORY_CLASSIFY_OPTIONS,
} from "@/libs/const";
import { debounce } from "lodash/function";
import moment from "moment";

export default {
  name: "InventoryCreate",
  data: () => ({
    item: { ...INVENTORY_DEFAULT },
    count: {},
    isLoading: false,
    customerOptions: [],
    statusOptions: [...INVENTORY_STATUS_OPTIONS],
    typeOptions: [...INVENTORY_TYPE_OPTIONS],
    classifyOptions: [...INVENTORY_CLASSIFY_OPTIONS],
  }),
  computed: {
    isDisabledBtn() {
      return (
        !this.item.id_warehouse ||
        !this.item.name ||
        !this.item.classify ||
        !this.item.type ||
        !this.item.start_date ||
        !this.item.end_date ||
        this.item.start_date > this.item.end_date
      );
    },
  },
  watch: {
    item: {
      handler() {
        this.getReport();
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    onClassifyChanged() {
      if (this.item.classify == 1) {
        this.item = { ...this.item, id_customer: null };
      }
    },
    async getCustomer() {
      this.item = { ...this.item, id_customer: null };
      this.customerOptions = [];
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        {
          id_warehouse: this.item.id_warehouse,
          is_active: true,
        }
      );
      const options = data.map((e) => ({
        value: e.id,
        text: e.company_name || "",
      }));
      this.customerOptions = [...options];
    },
    getReport: debounce(async function () {
      if (
        !this.item.id_warehouse &&
        !this.item.id_customer &&
        !this.item.skus
      ) {
        return false;
      }
      const { data } = await httpClient.post(
        "/inventory/v1/report-before-create",
        this.item
      );
      const today = moment().startOf("day").unix();
      const startDate = moment(this.item.start_date).startOf("day").unix();
      const totalDay = (startDate - today) / 86400;
      console.log("totalDay", totalDay);

      let forecast_sku = (data.total_forecast_sku * totalDay) / 10;
      console.log("forecast_sku", forecast_sku);
      forecast_sku = data.total_sku - forecast_sku;
      forecast_sku = forecast_sku > 0 ? forecast_sku : 0;

      let forecast_item = (data.total_forecast_handover * totalDay) / 10;
      console.log("forecast_item", forecast_item);
      forecast_item = data.total_item - forecast_item;
      forecast_item = forecast_item > 0 ? forecast_item : 0;
      this.count = { ...data, forecast_item, forecast_sku };
    }, 1000),
    onSkuPaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.item.skus = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.item.skus = codeSplit.join(";");
      } else {
        this.item.skus += value;
      }
    },
    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.codes = "";
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/inventory/v1/create", {
          ...this.item,
          skus: this.item.skus.split(";"),
        });
        this.isLoading = false;
        this.item = { ...INVENTORY_DEFAULT };
        this.$root.$emit("playSuccessAudio");
        this.$vToastify.success(this.$t("messages.create_success"));
        this.$emit("refreshData");
        this.cancel();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
